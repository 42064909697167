<template>
  <div
    @click.prevent="action ? action() : loadFile()"
    class="flex flex-col md:flex-row md:items-center w-full cursor-pointer"
  >
    <div class="flex min-w-0 gap-x-4 flex-1">
      <div
        class="h-12 w-12 hidden md:flex items-center flex-none justify-center rounded-full bg-slate-100 dark:bg-white/10"
      >
        <component class="text-primary size-6" :is="types[type] ?? IconFile" />
      </div>
      <div class="min-w-0 flex flex-col justify-center flex-1 break-all">
        <div
          class="block font-semibold leading-6 text-gray-900 dark:text-white mr-4"
          :class="textSize[size]"
          v-text="name"
        />
        <div
          class="mt-1 truncate block text-xs leading-5 text-gray-500 dark:text-gray-400 empty:hidden w-full"
          v-text="human_size"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import {
  IconFile,
  IconFileText,
  IconFileTypeJpg,
  IconFileTypePdf,
  IconMovie,
} from '@tabler/icons-vue'

const { id, name, extension, path } = defineProps({
  id: {
    type: Number,
    default: null,
  },
  type: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  extension: {
    type: String,
    default: null,
  },
  human_size: {
    type: String,
    default: '',
  },
  path: {
    type: String,
    default: '',
  },
  action: {
    type: Function,
    default: null,
  },
  size: {
    type: String,
    default: 'md',
  },
})

const types = ref({
  image: IconFileTypeJpg,
  pdf: IconFileTypePdf,
  video: IconMovie,
  other: IconFileText,
})

const ex = extension || path.split('.').pop()

const textSize = {
  md: 'text-sm',
  lg: 'text-sm md:text-lg',
  xl: 'text-sm md:text-lg lg:text-xl',
}

const loading = ref(false)

function loadFile() {
  const a = document.createElement('a')
  a.href = path
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()

  document.body.removeChild(a)
}
</script>
