import { ru } from '@formkit/i18n'
import { genesisIcons } from '@formkit/icons'
import { generateClasses } from '@formkit/themes'
import { createInput } from '@formkit/vue'

import Address from '@/components/formkit/Address.vue'
import ComboBox from '@/components/formkit/combobox'
import Date from '@/components/formkit/Date.vue'
import FileUploader from '@/components/formkit/file-uploader/FileUploader.vue'
import File from '@/components/formkit/File.vue'
import FIO from '@/components/formkit/FIO.vue'
import Phone from '@/components/formkit/Phone.vue'
import Select from '@/components/formkit/Select.vue'
import Switch from '@/components/formkit/Switch.vue'
import { asteriskPlugin } from '@/plugins/formkit-plugins.js'

import tailwindTheme from './formkit.theme.js'

function createLoadingCallback() {
  return function loadingCallback(node) {
    if (node.props.type !== 'file') return
    node.addProps(['loading'])
    if (node.props.loading)
      node.on('loading', (value) => node.props.loading(value.payload))
  }
}

export default {
  locales: { ru },
  locale: 'ru',
  config: {
    classes: generateClasses(tailwindTheme),
  },
  icons: {
    ...genesisIcons,
  },
  plugins: [asteriskPlugin, createLoadingCallback()],
  inputs: {
    combobox: createInput(ComboBox, {
      props: [
        'options',
        'labelBy',
        'search',
        'multiple',
        'inputOptions',
        'valueBy',
      ],
    }),
    phone: createInput(Phone),
    address: createInput(Address, {
      props: [
        'withoutMap',
        'overwriteTheme',
        'mapHeight',
        'enableClick',
        'fetchQuery',
      ],
    }),
    file: createInput(FileUploader, {
      family: 'files',
      props: [
        'max',
        'maxParallel',
        'multiple',
        'accept',
        'maxSize',
        'disabled',
        'readonly',
        'tiles',
      ],
    }),
    qselect: createInput(Select, {
      props: [
        'fuseKeys',
        'placeholder',
        'options',
        'labelBy',
        'trackBy',
        'emptyPlaceholder',
        'grouped',
        'multiple',
      ],
    }),
    switch: createInput(Switch, {
      props: ['title', 'duration', 'width', 'minHeight'],
    }),
    fio: createInput(FIO, {
      props: ['placeholder', 'disabled', 'readonly'],
    }),
    date: createInput(Date, {
      props: [
        'monthYear',
        'modelAuto',
        'maska',
        'placeholder',
        'range',
        'multiCalendars',
        'presetRanges',
        'minRange',
        'maxDate',
        'minDate',
        'startDate',
        'maxRange',
        'enableTimePicker',
        'timePicker',
        'weekPicker',
        'monthPicker',
        'yearPicker',
        'utc',
        'locale',
        'is24',
        'enableSeconds',
        'monthNameFormat',
        'hideInputIcon',
        'clearable',
        'closeOnScroll',
        'autoApply',
        'markers',
        'highlight',
        'highlightWeekDays',
        'highlightDisabledDays',
        'disabled',
        'readonly',
        'noSwipe',
        'allowedDates',
        'textInput',
        'cancelText',
        'selectText',
      ],
    }),
  },
}
