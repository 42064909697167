<template>
  <VueDatePicker
    ref="picker"
    v-model="model"
    :dark="theme === 'dark'"
    :range="range"
    :placeholder="placeholder"
    :preset-ranges="presetRanges"
    :min-range="minRange"
    :max-range="maxRange"
    :min-date="minDate"
    :start-date="startDate"
    :max-date="maxDate"
    :time-picker="timePicker"
    :week-picker="weekPicker"
    :month-picker="monthPicker"
    :year-picker="yearPicker"
    :utc="utc"
    :locale="locale"
    :cancel-text="cancelText"
    :select-text="selectText"
    :is-24="is24"
    :enable-seconds="enableSeconds"
    :format="format"
    :month-name-format="monthNameFormat"
    :hide-input-icon="hideInputIcon"
    :clearable="clearable"
    :close-on-scroll="closeOnScroll"
    :auto-apply="autoApply"
    :markers="markers"
    :highlight="highlight"
    :highlight-week-days="highlightWeekDays"
    :highlight-disabled-days="highlightDisabledDays"
    :disabled="disabled"
    :readonly="readonly"
    :no-swipe="noSwipe"
    :enable-time-picker="enableTimePicker"
    :text-input="textInput"
    :allowed-dates="allowedDates"
    :multi-calendars="multiCalendars"
    :model-auto="modelAuto"
    :month-change-on-scroll="false"
    @open="isMenuOpen = true"
    @closed="isMenuOpen = false"
    @update-month-year="changeMonthYear"
    @update:model-value="setDate"
  >
    <template #action-buttons>
      <UIButton
        class="w-full"
        title="Выбрать"
        size="sm"
        @click="picker.selectDate()"
      />
    </template>
    <template #action-preview></template>
    <template #input-icon>
      <IconCalendar
        class="mx-2 hover:text-primary transition-colors size-5"
        :class="[
          { 'text-primary': isMenuOpen },
          { 'pointer-events-none': !textInput },
        ]"
        @click="toggleMenu"
      />
    </template>
  </VueDatePicker>
</template>

<script setup>
import VueDatePicker from '@vuepic/vue-datepicker'
import { vMaska } from 'maska'
import { computed, inject, ref } from 'vue'

import { IconCalendar } from '@tabler/icons-vue'

import UIButton from '@/components/ui/UIButton.vue'

const props = defineProps({
  context: {
    type: Object,
    default: {},
  },
})

const emit = defineEmits(['monthYear'])

function changeMonthYear({ instance, month, year }) {
  if (!props.context.node.props['monthYear']) return
  props.context.node.props['monthYear']({ instance, month, year })
}

const theme = inject('theme')
const picker = ref(null)

const isMenuOpen = ref(false)
const model = ref(props.context.value || null)
const modelAuto = ref(props.context.modelAuto || false)
const placeholder = ref(props.context.placeholder || '13.05.1997')
const range = ref(props.context.range || false)
const multiCalendars = ref(props.context.multiCalendars || false)
const presetRanges = ref(props.context.presetRanges || [])
const minRange = ref(props.context.minRange || null)
const maxRange = ref(props.context.maxRange || null)
const minDate = ref(props.context.minDate || null)
const maxDate = ref(props.context.maxDate || null)
const startDate = ref(props.context.startDate || null)
const textInput = ref(
  props.context.textInput ?? {
    openMenu: false,
  },
)
const enableTimePicker = ref(props.context.enableTimePicker || false)
const timePicker = ref(props.context.timePicker || false)
const weekPicker = ref(props.context.weekPicker || false)
const monthPicker = ref(props.context.monthPicker || false)
const yearPicker = ref(props.context.yearPicker || false)
const utc = ref(props.context.utc || 'preserve')
const locale = ref(props.context.locale || 'ru-RU')
const is24 = ref(props.context.is24 || true)
const enableSeconds = ref(props.context.enableSeconds || false)
const monthNameFormat = ref(props.context.monthNameFormat || 'short')
const hideInputIcon = ref(props.context.hideInputIcon || false)
const clearable = ref(props.context.clearable || true)
const closeOnScroll = ref(props.context.closeOnScroll || false)
const autoApply = ref(props.context.autoApply ?? true)
const markers = ref(props.context.markers || [])
const highlight = ref(props.context.highlight || [])
const highlightWeekDays = ref(props.context.highlightWeekDays || null)
const highlightDisabledDays = ref(props.context.highlightDisabledDays || false)
const disabled = ref(props.context.disabled || false)
const readonly = ref(props.context.readonly || false)
const noSwipe = ref(props.context.noSwipe || false)
const allowedDates = computed(() => props.context.allowedDates ?? undefined)
const cancelText = ref(props.context.cancelText || 'Отменить')
const selectText = ref(props.context.selectText || 'Выбрать')

function getFormat() {
  if (yearPicker.value) return 'yyyy'
  if (range.value && timePicker.value) return 'HH:mm'
  if (range.value) return 'dd.MM.yyyy'
  if (monthPicker.value) return 'MM.yyyy'
  if (timePicker.value) return 'HH:mm'
  if (enableTimePicker.value) return 'dd.MM.yyyy HH:mm'
  return 'dd.MM.yyyy'
}

const format = ref(props.context.format || getFormat())

function toggleMenu() {
  !isMenuOpen.value ? picker.value.openMenu() : picker.value.closeMenu()
}

function setDate() {
  if (!model.value) {
    props.context.node.input(null)
    return
  }
  props.context.node.input(model.value)
}
</script>
